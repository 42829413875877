<template>
  <div class="col-12" v-if="pastZakaat && pastZakaat.length > 0 && !hidePast && zakaahName">
    <div class="row my-3">
      <div class="col-12 mb-3 smallheading">
        Use Past Zakaat Calculations
      </div>
      <div class="col-12 mb-3" v-for="past in pastZakaat" :key="past.zakaatDetailID">
        <div class="row py-2 border-bottom">
          <div class="col text-center">
            Person/Organisation<br/><b>{{past.zakaatName}}</b>
          </div>
          <div class="col text-center">
            Calculation Date<br/><b>{{displayDate(past.spotPriceDate)}}</b>
          </div>
          <div class="col text-center">
            Zakaat Payable<br/><b> {{defaultCurrency}}{{parseFloat(Math.round((past.totalBeforeTotalZakaat) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}</b>
          </div>
          <div class="col-auto p-0">
            <Button color="transparent" btnText="Use Values" icon="arrow" @buttonClicked="usePast(past)" class="w-100">
              <IconArrowForward color="white" size="size20" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'PreviousZakaah',
  props: ['zakaahName', 'zakaahDate'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      zakaah: {
        name: '',
        date: '',
        zakaatAssets: {
          commodities: [],
          cashAssets: [],
          policyAssets: [],
          investmentAssets: [],
          businessAssets: [],
          debtorAssets: [],
          partnershipAssets: []
        },
        zakaatLiabilities: {
          basicLiabilities: [],
          zakaatHomeLoanLiabilities: [],
          zakaatVehicleLiabilities: []
        }
      },
      zakaatDetailID: null,
      hidePast: false,
      isEdit: false
    }
  },
  beforeMount () {
    this.fetchPastZakaahs()
  },
  methods: {
    ...mapActions([
      'setZakaah',
      'fetchPastZakaahs',
      'setFromPast'
    ]),
    displayDate (val) {
      return DateTime.fromMillis(parseInt(val)).toFormat('yyyy-MM-dd')
    },
    setNameAndDate () {
      this.zakaah.name = this.zakaahName
      this.zakaah.date = this.zakaahDate
    },
    async usePast (val) {
      this.setNameAndDate()
      val.commodities.forEach(asset => {
        asset.commodityID = 0
      })
      val.cashAssets.forEach(asset => {
        asset.cashAssetID = 0
      })
      val.investmentAssets.forEach(asset => {
        asset.investmentAssetID = 0
      })
      val.businessAssets.forEach(asset => {
        asset.businessAssetID = 0
      })
      val.policyAssets.forEach(asset => {
        asset.policyAssetID = 0
      })
      val.debtorAssets.forEach(asset => {
        asset.debtorAssetID = 0
      })
      val.partnershipAssets.forEach(asset => {
        asset.partnershipAssetID = 0
      })
      val.basicLiabilities.forEach(liability => {
        liability.basicLiabilityID = 0
      })
      val.zakaatVehicleLiabilities.forEach(liability => {
        liability.zakaatVehicleLiabilityID = 0
      })
      val.zakaatHomeLoanLiabilities.forEach(liability => {
        liability.zakaatHomeLoanLiabilityID = 0
      })
      const fromPast = this.zakaah
      fromPast.zakaatAssets.commodities = val.commodities
      fromPast.zakaatAssets.cashAssets = val.cashAssets
      fromPast.zakaatAssets.investmentAssets = val.investmentAssets
      fromPast.zakaatAssets.businessAssets = val.businessAssets
      fromPast.zakaatAssets.policyAssets = val.policyAssets
      fromPast.zakaatAssets.debtorAssets = val.debtorAssets
      fromPast.zakaatAssets.partnershipAssets = val.partnershipAssets
      fromPast.zakaatLiabilities.basicLiabilities = val.basicLiabilities
      fromPast.zakaatLiabilities.zakaatVehicleLiabilities = val.zakaatVehicleLiabilities
      fromPast.zakaatLiabilities.zakaatHomeLoanLiabilities = val.zakaatHomeLoanLiabilities
      await this.setZakaah(fromPast)
      await this.setFromPast()
      this.hidePast = true
    }
  },
  computed: {
    ...mapGetters([
      'pastZakaat'
    ])
  }

}
</script>

<style scoped>
.smallheading {
  color: var( --green-color );
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
